/* global itemViewModuleComponent */
class itemViewListComponent extends itemViewModuleComponent {

    static name() {
        return "itemViewListComponent";
    }

    static componentName() {
        return "itemViewListComponent";
    }

    getTemplate() {
        return `<div class="card col-12 list-view">
                    <div class="discount" v-if="discount && item.CrossedOutPrice" >
                        <discountComponent :item="item" :currentItemPrice="currentItemPrice"/>
                    </div>
                    <div class="favorite-icon" v-if="$store.getters.getSession!=null">
                        <favoriteBtnComponent :itemCode="item.Code"/>
                    </div>
                     <div class="card-body px-0">
                        <div class="row">
                            <div class="col-sm-3 p-0 item-image item-detail-modal">
                                <router-link :to="'/view/'+encodeToURL(item.Code)">
                                    <itemSliderComponent :key="'slide'+item.Code" :item="item" :stockFlag="$store.getters.showStock" :container="false" v-if="$store.getters.getSetting && $store.getters.getSetting.DemoPhotos"></itemSliderComponent>
                                </router-link>
                            </div>
                            <div class="col-sm-9">
                                <h5 class="card-title">{{item.Name}}</h5>
                                <p class="card-text mb-1">{{item.Comment}}</p>
                                <p class="card-text mb-1">{{tr("ECOMITEMCODE")}} {{codeToShow}}</p>
                                <p class="card-text item-name" v-if="showStock">Stock {{stockQty}}</p>
                                <template v-if="$store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin">
                                    <p class="card-text item-price" :class="{'mb-0': item.PriceByUnit, 'mb-1': !item.PriceByUnit}">
                                        <strong v-if="item.CrossedOutPrice" class="crossedoutprice">$ {{item.CrossedOutPrice}}</strong>
                                        <strong>$ {{parseFloat(item.Price).toFixed(2)}} </strong>
                                        <small v-if="Currency">{{Currency}}/{{item.Unit}}</small><small v-else>{{item.Unit}}</small>
                                    </p>
                                    <p v-if="item.PriceByUnit" class="card-text mb-3 item-price-by-unit"><small>{{item.PriceByUnit}}</small></p>
                                </template>
                                <div class="row cart-section">
                                    <div class="col-sm-8 col-md-5">
                                        <div class="row">
                                            <template v-if="alertMsg">
                                                <div class="alert alert-danger p-1 ml-2" role="alert">{{tr(alertMsg)}}</div>
                                            </template>
                                            <div class="col">
                                                <template v-if="controlStock">
                                                    <div class="input-group qty-input col-auto pr-0 pr-xl-4">
                                                        <div class="input-group-prepend d-none d-sm-flex">
                                                            <button class="btn btn-outline-secondary" type="button" @click.stop="itemQtyDecrement"><i class="icon fas fa-minus"></i></button>
                                                        </div>
                                                        <input :ref="'carCant-'+item.Code" v-model="Qty" min='1' :max="getMaxStockByCode(item.Code)" class="form-control inputch" type="text" v-on:change.stop="onChangeQty">
                                                        <div class="input-group-append d-none d-sm-flex">
                                                            <button class="btn btn-outline-secondary" type="button" @click.stop="itemQtyIncrement"><i class="icon fas fa-plus"></i></button>
                                                        </div>
                                                    </div>
                                                    <template v-if="item.Qty>0">
                                                        <input :ref="'carCant-'+item.Code" v-model="Qty" class="form-control" min="1" :max="item.Qty" type="number">
                                                    </template>
                                                    <template v-else>
                                                        <input :ref="'carCant-'+item.Code" v-model="Qty" class="form-control" min="0" :max="item.Qty" type="number" disabled aria-describedby="withOutStockHelpBlock" >
                                                        <small id="withOutStockHelpBlock" class="form-text text-muted">
                                                            {{tr("Without Stock")}}
                                                        </small>
                                                    </template>
                                                </template>
                                                <template v-else>
                                                    <div class="input-group qty-input col-auto pr-0 pr-xl-4">
                                                        <div class="input-group-prepend d-none d-sm-flex">
                                                            <button class="btn btn-outline-secondary" type="button" @click.stop="itemQtyDecrement"><i class="icon fas fa-minus"></i></button>
                                                        </div>
                                                        <input :ref="'carCant-'+item.Code" v-model="Qty" min='1' class="form-control inputch" type="text" v-on:change.stop="onChangeQty">
                                                        <div class="input-group-append d-none d-sm-flex">
                                                            <button class="btn btn-outline-secondary" type="button" @click.stop="itemQtyIncrement"><i class="icon fas fa-plus"></i></button>
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col" :disabled="maxItemInCar<1">
                                        <div class="cart-button mt-3 mt-md-0">
                                            <button class="btn secondary-btn px-3 px-xl-5" :class="{'disabled': !canShopItem}" type="button" @click.stop="canShopItem? ItemComponentAddToCar(item) : null">
                                                <span class="icon"><i class="fa-icon fas fa-cart-plus"></i></span> {{tr("Load to Cart")}}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                     </div>
                  </div>`;
    }
}

itemViewListComponent.registerComponent();